import { render, staticRenderFns } from "./Fashion.vue?vue&type=template&id=25d5b28e&scoped=true"
import script from "./Fashion.vue?vue&type=script&lang=js"
export * from "./Fashion.vue?vue&type=script&lang=js"
import style0 from "./Fashion.vue?vue&type=style&index=0&id=25d5b28e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d5b28e",
  null
  
)

export default component.exports