<template>
	<div id="app">
		<div class="header">
			<div class="logo_img" @click="logoClick">
				<div class="header-item">
					<div class="header-logo">
						<img id="header-img" src="../assets/logo1.png">
					</div>
				</div>
				<div class="header-info">
					<div class="header-important">
						<div class="company_name">四川嘉合瑞信</div>
						<div class="header-title company_name">yfs66.com</div>
					</div>
				</div>
			</div>
			<div id="menu_index">
				<!-- web端菜单导航 -->
				<el-menu v-if="seen" :default-active="activeIndex" class="el-menu-demo menu-item" menu-trigger="click"
					text-color="#333" mode="horizontal" @select="handleSelect">
					<el-menu-item index="1" class="header-title">首页</el-menu-item>
					<el-menu-item index="2" class="header-title">产品微观</el-menu-item>
					<!-- <el-menu-item index="3" class="header-title">产品特点</el-menu-item> -->
					<!-- <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item> -->
					<el-menu-item index="5" class="header-title">关于我们</el-menu-item>
				</el-menu>
				<!-- 手机端菜单导航 -->
				<el-menu v-if="!seen" :default-active="activeIndex" class="el-menu-demo menu-item" menu-trigger="click"
					text-color="#333" mode="horizontal" @select="handleSelectMobile">
					<el-submenu index="1" class="header-title mobile_menu">
						<template slot="title">{{currentMenu}}</template>
						<el-menu-item index="1" class="header-title">首页</el-menu-item>
						<el-menu-item index="2" class="header-title">产品微观</el-menu-item>
						<!-- <el-menu-item index="3" class="header-title">产品特点</el-menu-item> -->
						<!-- <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item> -->
						<el-menu-item index="5" class="header-title">关于我们</el-menu-item>
					</el-submenu>
				</el-menu>
			</div>
			<div v-if="seen" class="seen-row">
				
			</div>
		</div>
		<div class="container clear">
			<router-view></router-view>
		</div>

		<div class="container2">
			<div class="con-border">
			</div>
		</div>
		<div class="container2">
			<el-row :gutter="20">
				<el-col :xs="24" :sm="12">
					<p class="footer_font">邮箱：yfs9988@yeah.net</p>
					<div class="footer_font ma">
						互联网ICP备案：蜀ICP备2023029629号-2
					</div>
					<div class="footer_font ma">
						©yfs66.com 四川嘉合瑞信版权所有
					</div>
				</el-col>
				<el-col :xs="24" :sm="12">
					<div><img :src="company.qrcode" class="w100" /></div>
					<div class="footer_font">关注玩潮一番赏</div>
				</el-col>
			</el-row>
		</div>
		<div id="back_to_top" ref="btn" @click="backTop" class="none">
			<p class="rtop">TOP</p>
			<img src="../assets/other/launch.png" class="launch" />
		</div>
	</div>
</template>

<script>
	export default {
		name: "Layout",
		data() {
			return {
				activeIndex: '1',
				company: {
					qrcode: require("../../static/other/qrcode_for_gh_313aff8aa446_258.jpg"),
				},
				scroll: '',
				timer: '',
				seen: true,
				currentMenu: '首页'
			};
		},
		methods: {
			handleSelect(key) {
				switch (key) {
					case '1':
						this.$router.push({
							name: "Index"
						});
						break;
					case '2':
						this.$router.push({
							name: "Index"
						});
						window.location.hash = "#产品微观";
						break;
						// case '2-2':
						//     this.$router.push({name: "Fruit"});
						//     break;
						// case '2-3':
						//     this.$router.push({name: "Fashion"});
						//     break;
					case '3':
						this.$router.push({
							name: "Index"
						});
						window.location.hash = "#产品特点";
						break;
						// case '4':
						//     this.$router.push({name: "Index"});
						//     window.location.hash = "#合作伙伴";
						//     break;
					case '5':
						this.$router.push({
							name: "Index"
						});
						window.location.hash = "#关于我们";
						break;
				}
			},
			handleSelectMobile(key) {
				switch (key) {
					case '1':
						this.$router.push({
							name: "Index"
						});
						this.currentMenu = '首页';
						break;
					case '2':

						this.$router.push({
							name: "Index"
						});
						window.location.hash = "#产品微观";
						this.currentMenu = '首页';
						break;

					case '3':
						this.$router.push({
							name: "Index"
						});
						window.location.hash = "#产品特点";
						this.currentMenu = '首页';
						break;
						// case '4':
						//     this.$router.push({name: "Index"});
						//     window.location.hash = "#合作伙伴";
						//     this.currentMenu='首页';
						//     break;
					case '5':
						this.$router.push({
							name: "Index"
						});
						window.location.hash = "#关于我们";
						this.currentMenu = '首页';
						break;
				}
			},
			handleScroll() {
				this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度

				if (this.$refs.btn !== undefined && this.$refs.btn !== null) {
					if (this.scroll > 600) { //判断滚动后高度超过400px,就显示
						this.$refs.btn.style.display = 'block';
					} else {
						this.$refs.btn.style.display = 'none'
					}
				}
			},
			backTop() { //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
				this.timer = setInterval(() => {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					if (osTop === 0) {
						clearInterval(this.timer)
					}
				}, 30)
			},
		
			menuSeen() {
				// 屏幕尺寸
				let screenHeight = document.body.clientWidth;
				if (screenHeight > 415) {
					this.seen = true;
				} else {
					this.seen = false;
				}
			},
			logoClick() {
				this.$router.push({
					name: "Index"
				});
				this.currentMenu = '首页';
			}
		},
		mounted() {
			window.location.hash = "/";
			this.menuSeen()
			window.addEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style scoped>
	.rtop {
		font-size: 0.625em;
		font-weight: bold
	}

	.launch {
		height: 45px;
		width: 35px
	}

	.none {
		display: none;
	}

	.clear {
		clear: both;
	}

	.w100 {
		width: 100px;
		height: 100px;
		border: 0
	}

	.ma {
		margin: auto;
	}

	.logo_img {
		float: left;
		width: 30%;
		cursor: pointer;
	}

	.header-item {
		display: flex;
		align-items: center;
		float: left;
		height: 65px;
	}

	.header-info {
		display: flex;
		float: left;
		height: 65px;
	}

	.menu-item {
		height: 65px;
		border: 0;
	}

	.seen-row {
		float: right;
		display: flex;
		align-items: center;
		height: 100%;
	}

	.container2 {
		width: 90%;
		margin: 20px auto;
	}

	.con-border {
		height: 1px;
		padding: 0;
		background-color: #999;
	}

	#back_to_top {
		position: fixed;
		bottom: 50px;
		right: 30px;
		cursor: pointer
	}

	.header-title {
		font-size: 1em;
	}

	.company_name {
		font-weight: normal;
		font-size: 1em;
	}

	.footer_font {
		font-size: 1em;
	}

	#menu_index {
		float: left;
		width: 50%;
		height: 100%
	}

	.header {
		margin: auto;
		width: 90%;
		height: 65px;
		/*background: #F56C6C;*/
	}

	@media screen and (max-device-width: 415px) {
		.logo_img {
			float: left;
			width: auto;
			cursor: pointer;
		}

		#back_to_top {
			position: fixed;
			bottom: 50px;
			right: 5px;
			cursor: pointer
		}

		.footer_font {
			font-size: 0.875em;
		}

		#menu_index {
			float: right;
			width: auto;
			height: 100%
		}

		.mobile_menu {}
	}

	#header-img {
		width: 50px;
		height: 50px;
		float: left;
		margin: auto;
	}

	.header-logo {
		height: 50px;
		width: 50px;
		align-self: center;
	}

	.header-important {
		float: left;
		margin-left: 6px;
		align-self: center;
	}

	.footer {
		display: flex;
		align-items: center;
		margin: 10px auto;
		width: 90%;
	}

	a {
		text-decoration: none;
	}

	a:visited {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}

	a:active {
		text-decoration: none;
	}
</style>